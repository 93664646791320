.legal-root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  margin: 0 1rem;

  @media screen and (min-width: 801px) {
    margin: 0 14rem;
  }
}
.legal-title {
  font-size: 28px;
  color: #ffffff;
  line-height: 120%;
  font-weight: 600;
}
.legal-subtitle {
  font-size: 10px;
  font-style: italic;
  color: #ffffff;
  font-weight: 600;
}
.legal-heading {
  font-size: 18px;
  color: #ffffff;
  margin-top: 12px;
  font-weight: 500;
}
.legal-description {
  font-size: 12px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.8);
  margin-top: -8px;
}

.legal-link {
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  margin: 0 4px;
}

.legal-bold {
  font-weight: 600;
  color: #ffffff;
}
