@media screen and (max-width: 800px) {
  .header {
    width: 100%;
    height: auto;
  }
  .title-icon {
    max-width: 180px;
    height: auto;
  }
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 48px;
  }

  .title-text {
    color: #ffffff;
    text-shadow: 2px #ffa07e;
    -webkit-text-stroke: 2px #ffa07e;
    font-size: 48px;
    line-height: 54px;
    font-weight: 600;
    width: 100%;
    max-width: 260px;
    display: flex;
    text-align: center;
    transform-origin: center top;
    transform: rotate(-5.95deg);
  }

  .main-right-bg {
    width: auto;
    height: 100%;
    max-height: 360px;
    transform: translateY(-72px) translateX(-18px);
    margin-bottom: -36px;
  }
  .main-left-bg {
    display: none;
  }

  .download {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 36px;
    width: 100%;
    max-width: 360px;
  }

  .platform {
    width: 120px;
    height: auto;
    aspect-ratio: 3/ 1;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    border-radius: 8px;
    background: none;
  }
  .platform-img {
    width: 100%;
    height: 100%;
  }

  .discover {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
    flex-wrap: wrap;
  }

  .discover-text {
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
  }

  .discover-tree {
    height: 0.8rem;
    width: auto;
  }

  .discover-link {
    font-size: 1rem;
    background: none;
    outline: none;
    border: none;
    color: #0184fc;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
  }

  .built-on-sentinel {
    height: 24px;
    width: auto;
    cursor: pointer;
  }
}
