@media screen and (min-width: 801px) {
  .header {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
  .title-icon {
    max-width: 12rem;
    height: auto;
    cursor: pointer;
  }
  .main {
    width: 100%;
    min-height: 700px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("./assets/pngs/main-left-bg.png"),
      url("./assets/pngs/main-right-bg.png");
    background-repeat: no-repeat;
    background-size: 350px, 28rem;
    background-position: left bottom -48px, top -36px right 24px;
    /* transform: translateY(-64px); */
  }
  .title-text {
    color: #ffffff;
    text-shadow: 2px #ffa07e;
    -webkit-text-stroke: 2px #ffa07e;
    font-size: 5rem;
    line-height: 100%;
    font-weight: 600;
    width: 100%;
    text-align: center;
    width: calc(100vw - 35rem);
  }
  .download {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
    max-width: 50vw;
  }
  .platform {
    width: 14rem;
    height: auto;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    border-radius: 8px;
    background: none;
  }

  .platform-img {
    width: 100%;
    height: 100%;
  }

  .discover {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-bottom: 24px;
  }
  .discover-text {
    font-size: 1rem;
    font-weight: 600;
  }
  .discover-tree {
    height: 0.8rem;
    width: auto;
  }
  .discover-link {
    font-size: 1rem;
    background: none;
    outline: none;
    border: none;
    color: #0184fc;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
  }
  .built-on-sentinel {
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
