img {
  pointer-events: none;
  user-select: none;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: #ffffff;
  overflow-x: hidden;
  outline: none;
  border: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
}

#root {
  margin: 0;
  width: 100%;
}

@media screen and (min-width: 801px) {
  .main-right-bg {
    display: none;
  }
}

.foot {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: calc(100% - 32px);
  padding: 0;
  margin-bottom: 36px;
  max-width: 1000px;
}

.footer-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
}

.footer-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 12px;
}

.footer-top .footer-li {
  font-size: 12px;
  font-weight: 600;
  border-right: 2px solid #ffffff;
  width: 100%;
  padding: 0 12px;
}

.footer-bottom .footer-li {
  font-size: 0.8rem;
  font-weight: 600;
  border-right: 2px solid #ffffff;
  width: 100%;
  max-width: 140px;
}

.footer-li:last-child {
  border: none;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 36px;
}

.social-github {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 16rem;
  font-size: 1rem;
  gap: 6px;
  cursor: pointer;
  background: none;
  border: 1px solid #ffbb60;
  outline: none;
  color: #ffffff;
  border-radius: 6px;
}

.social-telegram {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 16rem;
  font-size: 1rem;
  gap: 6px;
  cursor: pointer;
  background: none;
  border: 1px solid #34aadf;
  outline: none;
  color: #ffffff;
  border-radius: 6px;
}

@media screen and (min-width: 800px) {
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 0 16px;
    margin: 0;
    margin-top: 48px;
  }

  .footer-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 14px;
  }

  .footer-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin-bottom: 12px;
  }

  .footer-top .footer-li {
    font-size: min(1rem, 16px);
    font-weight: 600;
    border-right: 2px solid #ffffff;
    width: 100%;
    padding: 0 12px;
  }

  .footer-bottom .footer-li {
    font-size: min(1rem, 16px);
    font-weight: 600;
    border-right: 2px solid #ffffff;
    width: 100%;
    max-width: 240px;
  }

  .footer-li {
    font-size: 18px;
    font-weight: 600;
    border-right: 2px solid #ffffff;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
  }
  .footer-li:last-child {
    border: none;
  }
  .footer-top {
    border-right: 2px solid #ffffff;
  }
}
.legal {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 24px;
  margin: 24px 0;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.link-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  font-weight: 600;
}

.main-container {
  width: 100%;
}
